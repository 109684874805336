
import {Component, Prop, Vue} from "vue-property-decorator"
import Edition from "@/app/models/Edition"
import RationalImage from "@/components/RationalImage.vue"
import InfoButton from "@/components/InfoButton.vue"
import {CompareInfo} from "@/store/modules/CompareModule"
import { getVatLabel, resolveLeaseType } from "@/app/enums/LeaseType"
import RadioOrCheckBox from "@/components/RadioOrCheckBox.vue"

@Component({
  components: {
    RadioOrCheckBox,
    RationalImage,
    InfoButton
  },
})
export default class CarCard extends Vue {
  @Prop() car!: any
  @Prop() mileage!: number
  @Prop() duration!: number
  toggle: boolean = this.isCompared

  get currentlyCompared(): CompareInfo[] {
    return this.$store.state.compare.editions
  }

  get isCompared(): boolean {
    return (
      this.currentlyCompared.findIndex(
        (e: CompareInfo) => e.edition.carId === this.car.edition.car_id
      ) !== -1
    )
  }

  get vatLabel(): string {
    const vatLabel = this.$store.state.settings.leaseForm
    return getVatLabel(resolveLeaseType(vatLabel))
  }

  async toggleEdition() {
    if (this.toggle) {
      const edition = await Edition.show(this.car.edition.id)
      this.$store.commit("compare/setEdition", {edition, mileage: this.mileage, duration: this.duration})
    } else {
      this.$store.commit("compare/unsetEdition", this.car.id)
    }
  }
}
