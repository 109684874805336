import ConcernModel from '@/app/models/Concerns/Model';
import Edition from "@/app/models/Edition";

export default class Model extends ConcernModel {
    public name!: string;
    public editions!: Edition[];

    get casts(): any {
        return {
            editions: (editions: any[]) => editions.map((e: any) => new Edition(e))
        };
    }

    protected getUrl(): string {
        return 'models';
    }
}
