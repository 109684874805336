
// @ts-ignore
import get from "lodash/get"
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator"

@Component
export default class ObjectSelect extends Vue {
  @Model("change") selected?: { [key: string]: any }

  @Prop({ required: true }) valueKey!: string
  @Prop({ required: true }) displayKey!: string
  @Prop({ type: Array, required: true }) options!: { [key: string]: any }

  @Prop({ required: false, default: "" }) name!: string

  onChangeValue(): void {
    this.$emit(
      "change",
      this.options.find((option: { [key: string]: any }) => {
        return (
          this.getValue(option)!.toString() ===
          (this.$el as HTMLSelectElement).value
        )
      })
    )
  }

  isSelected(option: { [key: string]: any }): boolean {
    if (typeof this.selected === "undefined") {
      return false
    }

    return this.getValue(option) === this.getValue(this.selected)
  }

  getValue(option: { [key: string]: any }): string | null {
    return get(option, this.valueKey, null) || ""
  }

  getDisplay(option: { [key: string]: any }): string | null {
    return get(option, this.displayKey, null) || ""
  }
}
