import Model from './Concerns/Model';

export default class Matrix extends Model {
    public duration!: number;
    public mileage!: number;
    public price!: number;
    public price_per_kilometer!: number;
    public price_per_under_kilometer!: number;

    get casts(): any {
        return {
            duration(value: string): number {
                return parseInt(value);
            },
            mileage(value: string): number {
                return parseInt(value);
            },
            price(value: string): number {
                return parseFloat(value);
            },
            price_per_kilometer(value: string): number {
                return parseFloat(value);
            },
        };
    }

    protected getUrl(): string {
        return "matrices";
    }
}