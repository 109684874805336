import Model from './Concerns/Model';
import Client from "@/app/http/Client";

export default class Image extends Model {
    public hash!: string;
    public main!: boolean;
    public src!: string;

    protected getUrl(): string {
        return "images";
    }
}