
import Vue from "vue"
import FilterItem from "@/components/FilterItem.vue"
import Component from "vue-class-component"
import { Model, Prop, Watch } from "vue-property-decorator"
import { resolveLeaseType, getVatLabel } from "@/app/enums/LeaseType"
import Dropdown from "@/components/Dropdown.vue"
import FiltersDialog from "@/components/FiltersDialog.vue"

@Component({
  components: {
    // @ts-ignore
    FiltersDialog,
    // @ts-ignore
    Dropdown,
    FilterItem,
  },
})

export default class CarFilters extends Vue {
  @Model() appliedFilters!: object
  @Prop() aggregations!: object

  get filterPriceOptions() {
    return new Array(50).fill(undefined).map((value, index) => index * 50)
  }

  get filterMileageOptions() {
    return this.$store.state.filters.mileages
  }

  get filterDurationOptions() {
    return this.$store.state.filters.durations
  }

  get vatLabel(): string {
    const vatLabel = this.$store.state.settings.leaseForm
    return getVatLabel(resolveLeaseType(vatLabel))
  }
}
