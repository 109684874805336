
import { Component, Prop, Vue } from "vue-property-decorator"
import { Dictionary } from "@/app/helpers/Dictionary"

@Component
export default class CollapsibleThing extends Vue {
  @Prop({ default: () => false }) defaultOpen!: boolean
  @Prop({ default: () => "300ms" }) duration!: string
  @Prop({ default: () => "ease-out" }) curve!: string

  private height!: number
  private collapsed: boolean = true
  private initialised: boolean = false

  get collapsibleClasses(): Dictionary<boolean> {
    return {
      collapsed: this.collapsed && this.initialised,
      initialised: this.initialised,
    }
  }

  get alwaysClasses(): Dictionary<boolean> {
    return {
      collapsed: this.collapsed && this.initialised,
    }
  }

  get collapsibleStyle(): CSSStyleDeclaration {
    const style = {} as CSSStyleDeclaration

    if (!this.initialised) {
      return style
    }

    if (this.collapsed) {
      style.maxHeight = "0"
      style.paddingTop = "0px"
      style.paddingBottom = "0px"
    } else {
      style.maxHeight = this.height + "px"
    }

    return style
  }

  collapse() {
    this.collapsed = true
  }

  open() {
    this.collapsed = false
  }

  toggle(): void {
    this.collapsed = !this.collapsed
  }

  mounted() {
    this.init()
  }

  private init() {
    const collapsible = this.$refs.collapsible as HTMLDivElement

    this.height = collapsible.clientHeight

    this.collapsed = !this.defaultOpen
    this.initialised = true
  }
}
