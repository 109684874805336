<template>

  <div class="view view-confirmation">
    <ProgressBar :step="3" />

    <div class="main">
      <h1>Bedankt voor uw aanvraag!</h1>
      <div class="big-tick"></div>
      <p>
        Je hebt zojuist je aanvraag voor jouw leaseauto aan ons verzonden, hartelijk dank daarvoor!
        Een mooi moment met vele prettige kilometers in het vooruitzicht.
        Wij gaan aan de slag met de verwerking van je aanvraag en nemen zo snel mogelijk contact met je op.
      </p>

      <div class="btn-wrapper">
        <CallToActionButton
          class="btn-primary icon--left"
          icon="chevron-left"
          to="/"
        >
          Terug naar homepage
        </CallToActionButton>

        <CallToActionButton
          class="btn-primary icon--left"
          icon="chevron-left"
          :to="{
            name: 'car.editions',
            params: {
              carName: kebabCase($route.params.carName),
              id: $route.params.carId,
            },
          }"
        >
          Terug naar aanbod
        </CallToActionButton>
      </div>
    </div>
  </div>

</template>

<script>
import ProgressBar from "@/components/ProgressBar"
import CallToActionButton from "@/components/CallToActionButton"

export default {
  name: "OrderConfirmation",
  components: {
    ProgressBar,
    CallToActionButton,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";
@import "~@/assets/scss/media-queries";
@import "~@/assets/scss/globals";

.btn-wrapper {
  column-gap: $spacing-20;
  row-gap: $spacing-20;

  display: flex;
  flex-direction: column;

  @include breakpoint-up('lg') {
    flex-direction: row;
  }
}
</style>
