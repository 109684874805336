import { render, staticRenderFns } from "./EditionOptionContainer.vue?vue&type=template&id=c3dcc7e2&scoped=true"
import script from "./EditionOptionContainer.vue?vue&type=script&setup=true&lang=ts"
export * from "./EditionOptionContainer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./EditionOptionContainer.vue?vue&type=style&index=0&id=c3dcc7e2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3dcc7e2",
  null
  
)

export default component.exports