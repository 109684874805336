<template>
  <div
      ref="stickyEl"
      v-fake-sticky="{ sticky: props.sticky, offset: stickyElOffset }"
      class="side-bar"
      :class="{ 'is-sticky' : props.sticky }"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import debounce from 'lodash/debounce'
import { useGetOffsetElement } from "@/app/helpers/StickyOffset";

const props = defineProps({
  sticky: {
    type: Boolean,
    default: true
  }
})

// @todo make below reusable for different components.
const stickyEl = ref(null)
const stickyElOffset = ref(0)

const stickyElOffsetEl = ref(null)
const stickyElOffsetElObserver = ref(null)

onMounted(() => {
  stickyElOffsetEl.value = useGetOffsetElement(stickyEl.value?.offsetParent, 'top')

  if (stickyElOffsetEl?.value) {
    stickyElOffsetElObserver.value = new ResizeObserver(debounce((entries) => {
      stickyElOffset.value = (stickyElOffsetEl.value?.offsetHeight ?? 0) + (stickyElOffsetEl.value?.offsetTop ?? 0) ?? 0
    }, 200))
    stickyElOffsetElObserver.value.observe(stickyElOffsetEl.value)
    stickyElOffsetElObserver.value.observe(document.querySelector('html'))
  }
})

onBeforeUnmount(() => {
  if (stickyElOffsetElObserver?.value && stickyElOffsetEl?.value) {
    stickyElOffsetElObserver.value.unobserve(stickyElOffsetEl.value)
    stickyElOffsetElObserver.value.unobserve(document.querySelector('html'))
  }
})
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.side-bar {
  width: 100%;

  color: #4a4a4a;
}

.side-bar.right {
  background: #fafafa;

  padding: 25px;

  align-self: start;

  &.is-sticky {
    @include breakpoint-up('md') {
      position: sticky;
      top: 10px;
    }
  }

  @include breakpoint-up('md') {
    background: #ffffff;
    box-shadow: 0 0 10px 5px rgba(216, 216, 216, .5);

    margin-bottom: 30px;
  }

  > [class$="-card"]:not(:last-child) {
    margin-bottom: 16px;
  }

  .summary-card {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .reachability-card {
    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  .share-buttons-card {
    &:not(:first-child) {
      border-top: 1px solid #d8d8d8;

      padding-top: 16px;
    }
  }
}
</style>
