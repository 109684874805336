// @ts-nocheck

/**
 * Get element from data attribute value.
 *
 * Returns an HTML Element or null.
 * Returns the offset (HTML Element) element or null.
 *
 * @param el {HTMLElement}
 *   The element on which to find the 'sticky-selector--{position}' attribute.
 * @param attribute {string}
 *   The data attribute to find.
 * @returns {HTMLElement|null}
 *   Returns an HTML Element or null.
 */
export const useGetDataAttrValue = (el, attribute) => {
    const input = String(el.getAttribute(attribute))
    const offsetElement = input ? document.querySelector(input) : null
    return (!!offsetElement) ? offsetElement : null
}

/**
 * Get offset element.
 *
 * Gets the offset element for an element based on the
 * predefined data attribute style.
 *
 * @param el {HTMLElement}
 *   The element on which to find the 'sticky-selector--{position}' attribute.
 * @param position {string}
 *   The offset position (top, bottom, ...).
 * @returns {HTMLElement|null}
 *   Returns an HTML Element or null.
 */
export const useGetOffsetElement = (el, position = 'top') => {
    return useGetDataAttrValue(el, `sticky-selector--${ position }`)
}

/**
 * Get offset value.
 *
 * Gets the offset value for an element based on the
 * predefined data attribute style.
 *
 * @param el {HTMLElement}
 *   The element on which to find the 'sticky-selector--{position}' attribute.
 * @param position {string}
 *   The offset position (top, bottom, ...).
 * @returns {string|number}
 *   Returns a string with the offset, or 0.
 */
export const useGetOffsetValue = (el, position = 'top') => {
    const offsetElement = useGetOffsetElement(el, position) as HTMLElement
    let offsetValue = 0

    if (position === 'top') {
        offsetValue = (offsetElement?.offsetHeight ?? 0) + (offsetElement?.offsetTop ?? 0) ?? 0
    }

    if (position === 'bottom') {
        offsetValue = (offsetElement?.offsetHeight ?? 0) + (offsetElement?.offsetBottom ?? 0) ?? 0
    }

    return offsetValue
}
