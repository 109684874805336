var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cta-buttons-card"},[_c(_setup.CallToActionButton,{staticClass:"btn-primary",attrs:{"icon":"chevron-right","to":{
      name: 'order',
      params: {
        carName: _setup.kebabCase(_vm.config.edition?.carName),
        id: _vm.config.edition?.carId,
      },
      query: _setup.queryParams,
    }}},[_vm._v(" Bestel deze auto ")]),_c(_setup.CallToActionButton,{staticClass:"btn-secondary",attrs:{"icon":"chevron-right","to":{
      name: 'question',
      params: {
        carName: _setup.kebabCase(_vm.config.edition?.carName),
        id: _vm.config.edition?.carId,
      },
      query: _setup.queryParams,
    }}},[_vm._v(" Stel een vraag ")]),_c(_setup.CallToActionButton,{staticClass:"btn-secondary",attrs:{"icon":"chevron-right","to":{
      name: 'call',
      params: {
        carName: _setup.kebabCase(_vm.config.edition?.carName),
        id: _vm.config.edition?.carId,
      },
      query: _setup.queryParams,
    }}},[_vm._v(" Bel mij terug ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }