
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator"
import uniqueId from "lodash/uniqueId"

@Component({})
export default class RadioOrCheckBox extends Vue {
  @Model("change", { default: () => false }) public currentValue!: boolean | any
  @Prop() public name?: string
  @Prop({ default: () => true }) public isBox!: boolean
  @Prop({ default: () => true }) public textHighlighted!: boolean
  @Prop({ default: () => "checkbox" }) public type!: "checkbox" | "radio"
  @Prop({ default: () => 24 }) public radius?: number
  @Prop() public color?: string
  @Prop() public checkedColor?: string
  @Prop() public borderColor?: string
  @Prop({ default: () => 1 }) public borderWidth!: number
  @Prop({ default: () => "solid" }) public borderType?: string
  @Prop() public checked?: boolean
  @Prop() public tooltip?: string
  @Prop() value?: any

  private readonly uuid: string = uniqueId("radio-box-")

  protected get _name(): string {
    if (this.name != null) {
      return this.name
    }

    return this.uuid
  }

  protected get indicatorStyle(): any | CSSStyleDeclaration {
    return {
      "--ucl-radio-or-checkbox-radius": this.radius + "px",
      "--ucl-radio-or-checkbox-background": this.color,
      "--ucl-radio-or-checkbox-checked-background": this.checkedColor || this.color,
      "--ucl-radio-or-checkbox-border":
        this.borderColor != null
          ? [this.borderWidth + "px", this.borderType, this.borderColor].join(
              " "
            )
          : null,
    }
  }

  @Emit("change")
  protected change(e: InputEvent): boolean | any {
    const checkbox = e.target as HTMLInputElement

    if (this.value != null) {
      return checkbox.checked ? this.value : null
    }

    return checkbox.checked
  }
}
