<template>
  <div
      ref="stickyEl"
      v-fake-sticky="{ offset: stickyElOffset }"
      class="sticky-bar"
      :class="position"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import debounce from 'lodash/debounce'
import { useGetOffsetElement } from "@/app/helpers/StickyOffset";

const props = defineProps({
  position: {
    type: String,
    default: 'bottom',
    required: false
  }
})

const position = computed(() => {
  return ['top', 'bottom'].includes(props.position) ? props.position : 'bottom'
})

// @todo make below reusable for different components.
const stickyEl = ref(null)
const stickyElOffset = ref(0)

const stickyElOffsetEl = ref(null)
const stickyElOffsetElObserver = ref(null)

onMounted(() => {
  stickyElOffsetEl.value = useGetOffsetElement(stickyEl.value?.closest('.view')?.offsetParent, position.value)

  if (stickyElOffsetEl?.value) {
    stickyElOffsetElObserver.value = new ResizeObserver(debounce((entries) => {
      if (position.value === 'top') {
        stickyElOffset.value = (stickyElOffsetEl.value?.offsetHeight ?? 0) + (stickyElOffsetEl.value?.offsetTop ?? 0) ?? 0
      }

      if (position.value === 'bottom') {
        stickyElOffset.value = (stickyElOffsetEl.value?.offsetHeight ?? 0) + (stickyElOffsetEl.value?.offsetBottom ?? 0) ?? 0
      }
    }, 200))
    stickyElOffsetElObserver.value.observe(stickyElOffsetEl.value)
    stickyElOffsetElObserver.value.observe(document.querySelector('html'))
  }
})

onBeforeUnmount(() => {
  if (stickyElOffsetElObserver?.value && stickyElOffsetEl?.value) {
    stickyElOffsetElObserver.value.unobserve(stickyElOffsetEl.value)
    stickyElOffsetElObserver.value.unobserve(document.querySelector('html'))
  }
})
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";
@import "~@/assets/scss/media-queries";

$border-color: #d8d8d8;

// Sticky bar styles.
.sticky-bar {
  width: 100%;

  background: $white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .05);

  padding: $spacing-10;

  position: sticky;
  z-index: 999;

  will-change: top, bottom;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }

  @include breakpoint-up('sm') {
    width: calc(100% + $spacing-20 + $spacing-20); // 100% + wrapper padding;

    margin-left: -$spacing-20; // eliminate wrapper padding;

    &.top {
      margin-top: -$spacing-40; // eliminate wrapper padding;
    }

    &.bottom {
      margin-bottom: -$spacing-40; // eliminate wrapper padding;
    }
  }
}

// Sticky bar content styles.
.sticky-bar {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 10px;

  align-items: center;

  @include breakpoint-up('sm') {
    grid-template-columns: 150px 1fr minmax(0, 1fr);
    grid-column-gap: $spacing-40;
  }

  @include breakpoint-up('md') {
    display: none;
  }

  .summary {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
  }

  ::v-deep {
    .btn {
      width: 100%;

      margin-top: $spacing-10;
      margin-bottom: $spacing-10;

      grid-column: 1/3;

      @include breakpoint-up('sm') {
        width: auto;

        grid-column: auto;
      }
    }
  }
}
</style>