<template>

<div
  v-if="edition.images.length > 0"
  :class="{ 'without-thumbnails': edition.images.length < 2 }"
  class="car-gallery"
>

  <VueSlickCarousel
    ref="c1"
    :asNavFor="c2"
    :arrows="false"
    lazyLoad="ondemand"
    class="gallery main"
  >
    <img
      v-for="(item, index) in edition.images"
      :key="index"
      :src="item.src"
      :alt="`${ edition.brandName } ${ edition.modelName } - ${ edition.typeName }`"
    />
  </VueSlickCarousel>

  <VueSlickCarousel
    ref="c2"
    :asNavFor="c1"
    :arrows="true"
    :centerMode="true"
    :focusOnSelect="true"
    :variableWidth="false"
    :slidesToShow="c2SlidesToShow"
    centerPadding="60px"
    class="gallery thumbs"
  >
    <template #prevArrow="arrowOption">
      <button>
        <font-awesome-icon icon="chevron-left" size="2x" aria-hidden="true" />
        <span class="visually-hidden">Vorige</span>
      </button>
    </template>

    <img
      v-for="(item, index) in edition.images"
      :key="index"
      :src="item.src"
      :alt="`${ edition.brandName } ${ edition.modelName } - ${ edition.typeName }`"
    />

    <template #nextArrow="arrowOption">
      <button>
        <font-awesome-icon icon="chevron-right" size="2x" aria-hidden="true" />
        <span class="visually-hidden">Volgende</span>
      </button>
    </template>
  </VueSlickCarousel>

  <div class="disclaimer">
    <info-button size="11" /> Getoonde afbeeldingen dienen als illustratie en kunnen afwijken van de geconfigureerde en bestelde versie.
  </div>
</div>

</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import InfoButton from "@/components/InfoButton.vue";

const props = defineProps({
  edition: {},
});

const c1 = ref(null);
const c2 = ref(null);

const c2SliderWidth = ref(0);
const c2SlidesToShow = computed(() => {
  const itemWidth = 130;
  const minItems = 3;
  let maxItems = Math.floor(c2SliderWidth.value / itemWidth)
      maxItems = (maxItems % 2 === 0) ? maxItems + 1 : maxItems

  return Math.max(minItems, maxItems);
});

function handleResize() {
  c2SliderWidth.value = c2.value?.$el.clientWidth ?? c1SliderWidth.value;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";
@import "~@/assets/scss/media-queries";

// Basics.
.car-gallery {
  border-bottom: 1px solid #d8d8d8;

  padding-bottom: 10px;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// General gallery styles.
:deep(.gallery) {
  img {
    padding: 0;
    margin: 0;

    display: inline;

    object-fit: contain;

    aspect-ratio: 4/3;
  }

  &.main .slick-slide {
    pointer-events: none;
  }

  &.main img {
    //height: 179px;
    height: 269px;

    @include breakpoint-up(592) {
      height: 454px;
    }
  }

  &.thumbs img {
    height: 64px;

    padding: 0 5px;

    @include breakpoint-up(592) {
      height: 154px;
    }
  }
}

// Slick slider specific styles.
:deep(.slick-slider) {
  background-color: transparent;

  .slick-track {
    cursor: grab;
  }
}

:deep(.slick-slider.thumbs) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  .slick-list {
    flex-grow: 1;
  }

  .slick-slide {
    cursor: pointer;

    &,
    &[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
      opacity: .6;
      transition: opacity $animation-300 ease-in $animation-100;
    }

    &:hover,
    &.slick-center,
    &[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
      opacity: 1;
    }

    img {
      pointer-events: none;
    }
  }

  .slick-prev,
  .slick-next {
    width: $font-size-16;
    height: auto;

    font-size: $font-size-16;
    color: #4a4a4a;
    line-height: 1;

    align-content: center;

    position: relative;
    top: 0;

    transform: none;

    &:before,
    &:after {
      all: initial;
    }
  }

  .slick-prev,
  .slick-next {
    display: none;

    @include breakpoint-up(592) {
      display: block;
    }
  }

  .slick-prev {
    margin-right: 30px;

    left: 0;
  }

  .slick-next {
    margin-left: 30px;

    right: 0;
  }
}

// Disclaimer.
.disclaimer {
  font-size: 11px;
  font-weight: 300;
  color: #c3c3c3;
  text-align: center;

  margin-top: 20px;

  @include breakpoint-up(592) {
    margin-top: 20px;
  }

  svg {
    margin-right: 3px;
    margin-bottom: -1px;

    fill: #c3c3c3;
  }
}
</style>
