import Edition from '@/app/models/Edition';
import {Module, Mutation, VuexModule} from "vuex-module-decorators";

export interface CompareInfo {
    edition: Edition
    mileage: number
    duration: number
}

@Module({namespaced: true})
export default class CompareModule extends VuexModule {
    public editions: CompareInfo[] = [];

    @Mutation
    setEdition(edition: CompareInfo) {
        const index = this.editions.findIndex((e: CompareInfo) => e.edition.carId === edition.edition.carId);
        if (index === -1) {
            this.editions.push(edition);
        } else {
            this.editions[index] = edition;
        }
    }
    @Mutation
    unsetEdition(carId: number) {
        const index = this.editions.findIndex((e: CompareInfo) => e.edition.carId === carId);
        if (index !== -1) {
            this.editions.splice(index, 1);
        }
    }
}
