import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import FiltersModule from "@/store/modules/FiltersModule";
import CompareModule from "@/store/modules/CompareModule";
import SettingsModule from "@/store/modules/SettingsModule";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
});

export default new Vuex.Store({
    modules: {
        settings: SettingsModule,
        filters: FiltersModule,
        compare: CompareModule,
    },
    plugins: [vuexLocal.plugin],
});
