<script>
import ProgressBar from "@/components/ProgressBar"
import Client from "@/app/http/Client"
import SelectLocation from "@/components/SelectLocation"
import kebabCase from "lodash/kebabCase"
import QueryParams from "@/app/helpers/QueryParams"
import { getRelationTrackSiteId } from "@/app/helpers/RelationTrackSiteId"
import { isOperationalLease } from "@/app/enums/LeaseType"
import PriceConditionCard from "@/components/sidebar/PriceConditionCard.vue"
import ConversionButtonsCard from "@/components/sidebar/ConversionButtonsCard.vue"
import SideBar from "@/components/sidebar/SideBar.vue"
import SummaryCard from "@/components/sidebar/SummaryCard.vue"
import RadioOrCheckBox from "@/components/RadioOrCheckBox.vue"

export default {
  name: "OrderView",
  components: {
    SummaryCard,
    SideBar,
    ConversionButtonsCard,
    PriceConditionCard,
    SelectLocation,
    ProgressBar,
    RadioOrCheckBox
  },
  data() {
    return {
      config: null,
      salutation: null,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      street: null,
      houseNumber: null,
      zip: null,
      city: null,
      remark: null,
      location: null,
      cocNumber: null,
      companyName: null,
      showCompanyFields: false,
      relationTrackSiteId: null,
      checkboxes: [
        {
          name: "acceptedTermsAndConditions",
          text: "Ik heb de privacyverklaring gelezen",
          checked: false,
          required: true
        },
        {
          name: "wantsNewsletter",
          text: "Houd mij op de hoogte van nieuws en aanbiedingen",
          checked: false,
          required: false
        }
      ],
      showLocationSelector: true
    }
  },
  async beforeMount() {
    const configuration = await QueryParams.parse(this.$route.query, this.$route.params.editionId)
    this.config = configuration
  },
  created() {
    const leaseFormValue = this.$store.state.settings.leaseForm
    this.showCompanyFields = isOperationalLease(leaseFormValue)
    this.relationTrackSiteId = getRelationTrackSiteId()
  },
  methods: {
    isValid() {
      if (!this.isFormDirty()) {
        return false
      }
      return !Object.keys(this.fields).some(
        (key) => this.fields[key].valid === false
      ) && !this.checkboxes.some(
        (checkbox) => checkbox.required && !checkbox.checked
      )
    },
    isFormDirty() {
      return Object.keys(this.fields).some(
        (key) => this.fields[key].dirty || this.fields[key].changed
      )
    },
    hideSelectLocations() {
      this.showLocationSelector = false
    },
    async storeOrder() {
      await Client.post("orders", {
        edition: this.config.edition.id,
        duration: this.config.duration,
        mileage: this.config.mileage,
        totalPrice: this.config.totalPrice(),
        hasTradeInVehicle: this.config.hasTradeInVehicle,
        exchangeLicence: this.config.tradeInCarLicense,
        exchangeMileage: this.config.tradeInCarMileage,
        options: this.config.selectedOptions.map((option) => {
          return {
            "id": option.id,
            "code": option.code,
            "type": option.type,
            "name": option.name,
            "price": option.getMatrixPrice(this.config.duration, this.config.mileage)
          }
        }),
        salutation: this.salutation,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        zip: this.zip,
        street: this.street,
        city: this.city,
        houseNumber: this.houseNumber,
        locationId: this.location?.id,
        remark: this.remark,
        acceptedTermsAndConditions: this.checkboxes[0].checked,
        wantsNewsletter: this.checkboxes[1].checked,
        cocNumber: this.cocNumber,
        company: this.companyName,
        relationTrackSiteId: this.relationTrackSiteId
      })

      await this.$router.push("order-confirmation")
    },
    backUrlParameters() {
      let query = QueryParams.build(this.config)
      return {
        name: "car.editions.show",
        params: {
          carName: kebabCase(this.config.edition.carName),
          id: this.config.edition.carId,
          editionName: kebabCase(this.config.edition.name),
          editionId: this.config.edition.id
        },
        query
      }
    }
  }
}
</script>

<template>

  <div v-if="config" class="view view-contact-form">
    <ProgressBar :disabled="true" :step="2" :back-url-parameters="backUrlParameters()" />

    <div class="main">
      <h1>Bestelling afronden</h1>
      <p>
        Rond je aanvraag voor deze leaseauto af door je gegevens in te vullen op het onderstaande formulier.
        Wij nemen dan zo spoedig mogelijk contact met je op.
      </p>
      <form class="form">

        <div v-if="showCompanyFields" class="form-item form-section">
          <div class="form-item companyname">
            <label for="companyname">Bedrijfsnaam</label>
            <input
              id="companyname"
              v-model="companyName"
              v-validate="'min:2'"
              required
              name="companyname"
              type="text"
            />
          </div>

          <div class="form-item cocnumber">
            <label for="cocnumber">KVK nummer</label>
            <input
              id="cocnumber"
              v-model="cocNumber"
              v-validate="'min:8,max:8'"
              required
              name="cocnumber"
              maxlength="8"
              type="text"
              inputmode="numeric"
            />
          </div>
        </div>

        <div class="form-item salutation">
          <label>Aanhef</label>
          <div class="form-radios">
            <RadioOrCheckBox
              v-for="item in [{ key: 'dhr', value: 'Dhr.' }, { key: 'mevr', value: 'Mevr.' }]"
              :key="item.key"
              v-model="salutation"
              :is-box="false"
              :text-highlighted="false"
              checked-color="#0274B2"
              color="white"
              name="salutation"
              radius="16"
              type="radio"
              :value="item.key"
              v-validate
              required
            >
              {{ item.value }}
            </RadioOrCheckBox>
          </div>
        </div>

        <div class="form-item firstname">
          <label for="firstname">Voornaam</label>
          <input
            id="firstname"
            v-model="firstName"
            v-validate="'min:2'"
            required
            name="firstname"
            type="text"
          />
        </div>

        <div class="form-item lastname">
          <label for="lastname">Achternaam</label>
          <input
            id="lastname"
            v-model="lastName"
            v-validate="'min:2'"
            required
            name="lastname"
            type="text"
          />
        </div>

        <div class="form-item email">
          <label for="email">E-mailadres</label>
          <input
            id="email"
            v-model="email"
            v-validate
            required
            name="email"
            type="email"
          />
        </div>

        <div class="form-item phone">
          <label for="phone">Telefoonnummer</label>
          <input
            id="phone"
            v-model="phoneNumber"
            v-validate="'min:10|regex:[\+\-0-9]'"
            required
            name="phone"
            type="tel"
          />
        </div>

        <div class="form-item street">
          <label for="street">Straat</label>
          <input
            id="street"
            v-model="street"
            v-validate="'min:2'"
            required
            name="street"
            type="text"
          />
        </div>

        <div class="form-item housenumber">
          <label for="housenumber">Nummer</label>
          <input
            id="housenumber"
            v-model="houseNumber"
            v-validate="'required|min:1'"
            name="housenumber"
            type="text"
          />
        </div>

        <div class="form-item postalcode">
          <label for="postalcode">Postcode</label>
          <input
            id="postalcode"
            v-model="zip"
            v-validate="'required|min:6'"
            name="postalcode"
            type="text"
          />
        </div>

        <div class="form-item city">
          <label for="city">Plaats</label>
          <input
            id="city"
            v-model="city"
            v-validate="'min:2'"
            name="city"
            type="text"
            required
          />
        </div>

        <div class="form-item question">
          <label for="question">Opmerking</label>
          <textarea
            id="question"
            v-model="remark"
            name="question"
            rows="5"
          />
        </div>

        <div v-if="showLocationSelector" class="form-item location">
          <label for="locations">Kies een locatie</label>
          <div class="form-select">
            <SelectLocation
              :id="'locations'"
              v-model="location"
              v-validate="'required'"
              :name="'locations'"
              :force="true"
              :required="true"
              @hide="hideSelectLocations($event)"
              class="plain"
            >
              <template #placeholder>Selecteer een locatie</template>
            </SelectLocation>
          </div>
        </div>

        <div class="form-item checkboxes">
          <div class="form-checkboxes">
            <RadioOrCheckBox
              v-for="checkbox in checkboxes"
              :key="checkbox.name"

              v-model="checkbox.checked"
              :border-color="checkbox.checked ? '#0274b2' : '#979797'"
              :is-box="true"
              :text-highlighted="false"
              checked-color="#0274B2"
              color="white"
              :name="checkbox.name"
              radius="16"
              type="checkbox"
              v-validate
              :required="checkbox.required"
            >
              {{ checkbox.text }}
            </RadioOrCheckBox>
          </div>
        </div>

        <div class="form-item form-actions">
          <div>
            <button :disabled="!isValid()" @click.prevent="storeOrder">
              Bestelling afronden
              <font-awesome-icon icon="chevron-right" />
            </button>
          </div>
        </div>
      </form>
    </div>

    <SideBar class="right">
      <SummaryCard title="Jouw overzicht" :config="config" :showThumb="true"></SummaryCard>
      <PriceConditionCard :config="config"></PriceConditionCard>
    </SideBar>
  </div>

</template>

<style lang="scss" scoped>
@import "~@/assets/scss/form-contact";
</style>