
import {Component, Vue, Watch} from "vue-property-decorator"
import SideBar from "@/components/sidebar/SideBar.vue"
import CarFilters from "@/components/CarFilters.vue"
import Filters from "@/app/models/Filters"
import CarCard from "@/components/CarCard.vue"
import Client from "@/app/http/Client"
import {
  SearchAggregations,
  SearchCarInfo,
  SearchCarResponse,
} from "@/app/http/types"
import ListPager from '@/components/ListPager.vue'

@Component({
  metaInfo() {
    return {
      title: "Overzicht van voertuigen",
    }
  },
  components: {
    CarCard,
    // @ts-ignore
    SideBar,
    // @ts-ignore
    CarFilters,
    // @ts-ignore
    ListPager,
  },
})

export default class Home extends Vue {
  cars: SearchCarInfo[] = []
  filters: any = {fromPrice: 0, toPrice: 2450, mileage: undefined, duration: undefined}
  aggregations: SearchAggregations = {
    brand: {},
    fuelTypes: {},
    transmissions: {},
    bodyWorks: {},
  }

  perPage: number = 12
  totalItems: number = 0
  currentPage: number = 1
  totalPages: number = 1
  orderBy: object | null = {
    property: "matrix.price",
    isAscending: true,
  }
  loading: boolean = true

  sortingOptions: object[] = [
    {
      value: {property: "matrix.price", isAscending: true,},
      label: "Prijs: Laag - Hoog",
    },
    {
      value: {property: "matrix.price", isAscending: false,},
      label: "Prijs: Hoog - Laag",
    },
    {
      value: {property: "carName.keyword", isAscending: true,},
      label: "Naam: A t/m Z",
    },
    {
      value: {property: "carName.keyword", isAscending: false,},
      label: "Naam: Z t/m A",
    },
  ]

  takeOptions: object[] = [
    {value: 12, label: 12,},
    {value: 24, label: 24,},
    {value: 36, label: 36,},
  ]

  async beforeMount() {
    const filters = await Filters.get()
    this.$store.commit("filters/setFilters", filters)

    const query = this.$route.query
    if (query.mileage) {
      const mileage = parseInt(<string>query.mileage)
      if (filters.mileages.find((obj: any) => obj.value === mileage)) {
        this.filters.mileage = mileage
      }
    }

    if (query.duration) {
      const duration = parseInt(<string>query.duration)
      if (filters.durations.find((obj: any) => obj.value === duration)) {
        this.filters.duration = duration
      }
    }

    await this.searchCars()
  }

  // @todo manage filter, sort and pager params with router through query params?
  @Watch("currentPage")
  async searchCars() {
    this.cars = []
    this.loading = true
    let response = await Client.get<SearchCarResponse>("cars", {
      params: {
        ...this.filters,
        perPage: this.perPage,
        orderBy: this.orderBy,
        page: this.currentPage,
      },
    })
    this.loading = false
    this.cars = response.data.data
    this.aggregations = response.data.meta.aggs
    this.totalItems = response.data.meta.total
    this.totalPages = response.data.meta.last_page
    this.currentPage = this.currentPage > this.totalPages ? 1 : this.currentPage
  }
}
