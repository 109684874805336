import Model from './Concerns/Model';
import Matrix from "@/app/models/Matrix";
import Image from "@/app/models/Images";
import PriceIndication from "@/app/models/PriceIndication";
import Option from "@/app/models/Option";

export default class Edition extends Model {
    public id!: number;
    public carId!: number;
    public brandId!: number;
    public modelId!: number;
    public typeId!: number;
    public fuelTypeId!: number;
    public transmissionId!: number;
    public brandName!: string;
    public modelName!: string;
    public typeName!: string;
    public nickname!: string;
    public usp!: string | null;
    public seo?: string;
    public year!: number
    public doors!: number;
    public bodyWork!: string;
    public energyLabel!: string;
    public carbonDioxideEmission!: number;
    public gears!: number;
    public addition!: number;
    public consumerAdvicePrice!: number;
    public consumerPrice!: number;
    public consumption!: string;
    public fuelType!: string;
    public transmissionType!: string;
    public transmissionTypeText!: string;
    public powerKw!: number;
    public powerHp!: number;
    public acceleration!: number;
    public topSpeed!: number;
    public weight!: number;
    public range!: number;
    public contractText!: string | null;
    public priceMatrix!: Matrix[];
    public images!: Image[];
    public options!: Option[];

    get fullName(): string {
        return [this.brandName, this.modelName, this.typeName].join(' ');
    }

    get name(): string {
        return [this.typeName, this.nickname].join(' ');
    }

    get carName(): string {
        return [this.brandName, this.modelName].join(' ');
    }

    get mainImage(): Image {
        let image = this.images.find(i => i.main)

        if (image == null)
            image = this.images[0];

        return image;
    }

    public get casts(): any {
        return {
            images: (models: object[]) => models.map(data => new Image(data)),
            priceMatrix: (models: object[]) => models.map(data => new Matrix(data)),
            options: (models: object[]) => models.map(data => new Option(data)),
        };
    }

    get fromPrice(): PriceIndication {
        return this.getFromPrice(null, null)
    }

    get defaultOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'default';
        })
    }

    get colorOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'color';
        })
    }

    get extraOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'extra';
        })
    }

    get accessoryOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'accessory';
        })
    }

    get componentOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'component';
        })
    }

    get ownRiskOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'own-risk';
        })
    }

    get insuranceOptions(): Option[] {
        return this.options.filter((option) => {
            return option.type === 'insurance';
        })
    }

    getMileages(duration?: number): number[] {
        let matrix = this.priceMatrix;

        if (duration != null && !isNaN(duration)) {
            matrix = matrix.filter((matrix: Matrix) => matrix.duration === duration);
        }

        const mileages = matrix.map((matrix: Matrix) => matrix.mileage);

        return mileages.filter((m: number, index: number) => mileages.indexOf(m) === index).sort((a, b) => a - b);
    }

    getDurations(mileage?: number): number[] {
        let matrix = this.priceMatrix;

        if (mileage != null && !isNaN(mileage)) {
            matrix = matrix.filter((matrix: Matrix) => matrix.mileage === mileage);
        }

        const durations = matrix.map((matrix: Matrix) => matrix.duration);

        return durations.filter((d: number, index: number) => durations.indexOf(d) === index).sort((a, b) => a - b);
    }

    getPriceIndication(matrixLine: Matrix): PriceIndication {
        return {
            edition: this,
            price: matrixLine.price,
            mileage: matrixLine.mileage,
            price_per_kilometer: matrixLine.price_per_kilometer,
            duration: matrixLine.duration,
            finalPrice: matrixLine.price,
        }
    }

    getMatrixLine(mileage: number, duration: number): Matrix | undefined {
        return this.priceMatrix.find(matrix => matrix.duration === duration && matrix.mileage === mileage);
    }

    getFromPrice(mileage: number | null, duration: number | null): PriceIndication {
        if (mileage && duration) {
            const line = this.getMatrixLine(mileage, duration)
            if (line) {
                return this.getPriceIndication(line);
            }
            mileage = null
            duration = null
        }

        return this.priceMatrix.reduce((current: PriceIndication, matrixLine: Matrix) => {
            if (
                (mileage === null || matrixLine.mileage === mileage) &&
                (duration === null || matrixLine.duration === duration) &&
                current.price > matrixLine.price
            ) {
                return this.getPriceIndication(matrixLine)
            }

            return current;
        }, {
            price: Number.MAX_VALUE,
            price_per_kilometer: Number.MAX_VALUE,
            finalPrice: Number.MAX_VALUE,
            duration: null,
            mileage: null,
        });
    }


    public getMatrixPriceKm(duration: number|null, mileage: number|null): number {
        if (duration == null && mileage == null) {
            return this.fromPrice.price_per_kilometer;
        }

        const matrixLine = this.priceMatrix.find(matrix => matrix.duration === duration && matrix.mileage === mileage);

        return matrixLine?.price_per_kilometer || 0;
    }

    public getMatrixPriceUnderKm(duration: number|null, mileage: number|null): number {
        if (duration == null && mileage == null) {
            return this.fromPrice.price_per_kilometer;
        }

        const matrixLine = this.priceMatrix.find(matrix => matrix.duration === duration && matrix.mileage === mileage);

        return matrixLine?.price_per_under_kilometer || 0;
    }

    public getMatrixPrice(duration: number | null, mileage: number | null): number {
        if (duration == null && mileage == null) {
            return this.fromPrice.price;
        }

        const matrixLine = this.priceMatrix.find(matrix => matrix.duration === duration && matrix.mileage === mileage);

        return matrixLine?.price || 0;
    }

    public sortedOwnRiskOptions(duration: number | null, mileage: number | null) {

        return this.ownRiskOptions.sort(function (optionA, optionB) {
            let priceA = optionA.getMatrixPrice(duration, mileage)
            let priceB = optionB.getMatrixPrice(duration, mileage)

            return priceA - priceB
        })
    }

    protected getUrl(): string {
        return "/editions";
    }
}
