
import { Component, Vue } from "vue-property-decorator"
import Edition from "@/app/models/Edition"
import ObjectSelect from "@/components/ObjectSelect.vue"
import RationalImage from "@/components/RationalImage.vue"
import CollapsibleThing from "@/components/CollapsibleThing.vue"
import RationalDiv from "@/components/RationalDiv.vue"
import waitFor from "@/app/helpers/waitFor"
import Car from "@/app/models/Car";
import { CompareInfo } from "@/store/modules/CompareModule";
import edition from "@/views/Edition.vue";
import CallToActionButton from "@/components/CallToActionButton.vue"
import ProgressBar from "@/components/ProgressBar.vue"
import StickyBar from "@/components/StickyBar.vue"

type Column = {
  index: number
  edition: Edition | null
  car: Car | null
  mileage: number | null
  duration: number | null
  brand?: number | null
  model?: number | null
  selectBasedOn: boolean
}

@Component({
  metaInfo() {
    return {
      title: "Vergelijking bekijken",
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow,noydir,noodp",
        },
      ],
    }
  },
  components: {
    RationalDiv,
    CollapsibleThing,
    RationalImage,
    ObjectSelect,
    // @ts-ignore
    CallToActionButton,
    ProgressBar,
    // @ts-ignore
    StickyBar,
  },
})
export default class Compare extends Vue {
  columns: Column[] = []
  currentColumnIndex: number = 0
  mileage: number | null = null
  duration: number | null = null
  compareOptionsAll?: string[]

  documentWidth: number = document.body.clientWidth

  get stepSize(): number {
    return ((this.documentWidth * 3 - 144) / 3)
  }

  get editions(): CompareInfo[] {
    return this.$store.state.compare.editions.map((e: CompareInfo): CompareInfo => {
      return {
        edition: new Edition(e.edition),
        mileage: e.mileage,
        duration: e.duration,
      }
    })
  }

  confirmColumn(column: Column): void {
    this.columns.forEach((col: Column) => {
      if (col.index !== column.index) {
        return
      }

      col.mileage = column.mileage
      col.duration = column.duration
      col.selectBasedOn = false
    })
  }

  getColumnEditions(
      column: Column
  ): Edition[] {
    return column.car ? column.car.editions : []
  }

  editionUpdated(column: Column): void {
    if (column.edition === null) {
      return
    }
    let price = column.edition.getFromPrice(column.mileage, column.duration)
    column.duration = price.duration
    column.mileage = price.mileage
  }

  async beforeMount() {
    const mileage = this.$route.query.mileage;
    const duration = this.$route.query.duration;
    if (mileage) {
      this.mileage = parseInt(<string>mileage);
    }
    if (duration) {
      this.duration = parseInt(<string>duration);
    }

    if (this.editions) {
      this.compareOptionsAll = this.editions.flatMap(object => object.edition?.defaultOptions).map(object => object.name);
    }

    this.initColumns()
    for (const column of this.columns) {
      if (column.edition?.carId) {
        column.car = await Car.show(column.edition?.carId)
      }
    }
  }

  async mounted() {
    await waitFor(() => this.$refs.indicatorLine != null && this.$refs.compareView != null )
    this.updateIndicatorLine()
  }

  scrollTo(index: number) {
    const element = this.$refs.cars as HTMLElement
    element.scrollTo({
      top: 0,
      left: this.stepSize * index,
      behavior: "smooth",
    })
  }

  onScrollHorizontal(e: UIEvent) {
    const element = e.target as HTMLElement
    const indexFinder: { start: number; end: number }[] = [
      {
        start: 0,
        end: this.stepSize * 0.5,
      },
      {
        start: this.stepSize * 0.5,
        end: this.stepSize * 1.5,
      },
      {
        start: this.stepSize * 1.5,
        end: this.stepSize * 3,
      },
    ]

    this.currentColumnIndex = indexFinder.findIndex(
        (prospect: { start: number; end: number }) =>
            prospect.start <= element.scrollLeft &&
            prospect.end > element.scrollLeft
    )

    this.updateIndicatorLine(element.scrollLeft)
  }

  private updateStepSize() {
    const compareViewEl = this.$refs.compareView as HTMLElement
    this.documentWidth = compareViewEl.offsetWidth
  }

  private updateIndicatorLine(scrollLeft: number = 0) {
    this.updateStepSize()

    const indicatorLine = this.$refs.indicatorLine as HTMLElement
    const delta = scrollLeft - this.stepSize

    if (scrollLeft - this.stepSize / 2 < 0) {
      const percentage = ((-1 * (scrollLeft - this.stepSize / 2)) /this.stepSize) * 100
      indicatorLine.style.left = `${ percentage }%`
      indicatorLine.style.right = '0'
    }
    else if (delta > 0) {
      const theta = this.stepSize / 2 - delta / 2
      const percentage = (theta / this.documentWidth) * 100
      indicatorLine.style.left = '0'
      indicatorLine.style.right = `${(50 - percentage)}%`
    }
  }

  private initColumns(): void {
    const columns: Column[] = this.editions.map(
        (info: CompareInfo, index: number): Column => {
          let mileage = this.mileage === undefined ? null : this.mileage
          let duration = this.duration === undefined ? null : this.duration
          return {
            index: index,
            edition: info.edition,
            car: null,
            mileage: info.mileage || mileage,
            duration: info.duration || duration,
            selectBasedOn: false,
          }
        }
    )

    while (columns.length < 3) {
      columns.push({
        index: columns.length,
        edition: null,
        car: null,
        mileage: this.mileage,
        duration: this.duration,
        selectBasedOn: false,
      } as Column)
    }

    this.columns = columns
  }
}
