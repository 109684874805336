
import ProgressBar from "@/components/ProgressBar.vue"
import {Component, Vue} from "vue-property-decorator"
import EditionCard from "@/components/EditionCard.vue"
import Car from "@/app/models/Car"

@Component({
  metaInfo() {
    return {
      // @ts-ignore
      title: this.seoTitle,
    }
  },
  components: {EditionCard, ProgressBar},
})
export default class Model extends Vue {
  seoTitle: string = ""
  mileage: number | null = null
  duration: number | null = null
  car: Car = new Car({})

  closeInformation: boolean = true;
  closeOptions: boolean = true;

  async beforeMount() {
    const mileage = this.$route.query.mileage;
    const duration = this.$route.query.duration;
    if (mileage) {
      this.mileage = parseInt(<string>mileage);
    }
    if (duration) {
      this.duration = parseInt(<string>duration);
    }
    this.car = await Car.show(this.$route.params.id)
    this.seoTitle = this.car.fullName
  }


  onToggleInformation(val: boolean) {
    this.closeInformation = val
  }


  onToggleOptions(val: boolean) {
    this.closeOptions = val
  }
}
