import {Dictionary} from "vue-router/types/router";
import Edition from "@/app/models/Edition";
import Option from "@/app/models/Option";
import {Vue} from "vue-property-decorator";

export class Configuration {
    mileage: number | null = null
    duration: number | null = null
    hasTradeInVehicle: boolean = false
    tradeInCarLicense: string | null = null
    tradeInCarMileage: string | null = null
    selectedOptions: Option[] = []
    edition: Edition | null = null

    public editionPrice() {
        if (!this.edition) {
            return 0
        }
        return this.edition.getMatrixPrice(this.duration, this.mileage)
    }

    public totalPrice(): number {
        if (!this.edition) {
            return 0
        }

        let price = this.edition.getMatrixPrice(this.duration, this.mileage)
        if (this.selectedOptions) {
            price += this.selectedOptions.reduce((value, option) => {
                if (option == null) {
                    return value
                }

                return value + option.getMatrixPrice(this.duration, this.mileage)
            }, 0)
        }

        return price
    }

    public selectOption(optionToSelect: Option) {
        const idx = this.selectedOptions.findIndex((option) => {
            if (optionToSelect.type === 'color' || optionToSelect.type === 'own-risk') {
                return option.type === optionToSelect.type
            }
            return option.id === optionToSelect.id && option.type === optionToSelect.type
        })
        if (idx >= 0) {
            // Use this instead of `this.selectedOptions[idx] = optionToSelect` to keep reactivity
            Vue.set(this.selectedOptions, idx, optionToSelect)
        } else {
            this.selectedOptions.push(optionToSelect)
        }
    }

    public deSelectOption(optionToSelect: Option) {
        const idx = this.selectedOptions.findIndex((option) => {
            return option.id === optionToSelect.id && option.type === optionToSelect.type
        })
        if (idx >= 0) {
            this.selectedOptions.splice(idx, 1)
        }
    }

    public isOptionSelected(optionToCheck: Option) {
        return this.selectedOptions.findIndex((option) => {
            return option.id === optionToCheck.id && option.type === optionToCheck.type
        }) !== -1
    }

    public firstOptionForType(optionType: string) {
        return this.selectedOptions.find((option) => option.type === optionType)
    }

    public optionTotalsForType(optionType: string) {
        return this.selectedOptions
            .filter((option) => option.type === optionType)
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.getMatrixPrice(this.duration, this.mileage)
            }, 0)
    }
}

export default class QueryParams {
    duration: string | null = null
    mileage: string | null = null
    color: string | null = null
    own_risk: string | null = null
    trade_in: string | null = null
    ti_license: string | null = null
    ti_mileage: string | null = null
    opt: string[] = []

    public static async parse(query: Dictionary<string | (string | null)[]>, editionId: string): Promise<Configuration> {
        let config = new Configuration()

        if (query.mileage) config.mileage = parseInt(<string>query.mileage)
        if (query.duration) config.duration = parseInt(<string>query.duration)

        config.edition = await Edition.show(editionId)
        if (config.edition === null) {
            return config
        }

        const fromPrice = config.edition.getFromPrice(config.mileage, config.duration)
        // @ts-ignore
        config.mileage = fromPrice.mileage
        // @ts-ignore
        config.duration = fromPrice.duration

        if (query.trade_in === 'true') {
            config.hasTradeInVehicle = true
            if (query.ti_license) {
                config.tradeInCarLicense = <string>query.ti_license
            }
            if (query.ti_mileage) {
                config.tradeInCarMileage = <string>query.ti_mileage
            }
        }

        if (query.opt) {
            const queryOptions = Array.isArray(query.opt) ? <string[]>(query.opt as unknown) : [<string>query.opt]
            queryOptions.forEach((queryOption: string) => {
                const selectedOption = config.edition?.options.find((option) => {
                    return option.id.toString() === queryOption &&
                        (option.type === 'extra' || option.type === 'accessory' || option.type === 'component')
                });
                if (selectedOption) {
                    config.selectedOptions.push(selectedOption)
                }

            })
        } else {
            const editionOptions = config.edition?.options
            editionOptions?.forEach((option) => {
                if (option?.default) {
                    config.selectedOptions.push(option)
                }
            })
        }

        if (query.color) {
            const colorOption = config.edition.options.find((option) => {
                    return option.id.toString() === query.color && option.type === 'color'
                }
            )
            if (colorOption) {
                config.selectedOptions.push(colorOption)
            }
        }

        if (query.own_risk) {
            const ownRiskOption = config.edition.options.find((option) => {
                    return option.id.toString() === query.own_risk && option.type === 'own-risk'
                }
            )
            if (ownRiskOption) {
                config.selectedOptions.push(ownRiskOption)
            }
        }

        return config
    }

    public static build(config: Configuration): QueryParams {
        let query = new QueryParams()
        if (config.duration) {
            query.duration = config.duration.toString()
        }
        if (config.mileage) {
            query.mileage = config.mileage.toString()
        }

        config.selectedOptions.forEach((option) => {
            if (option.type === 'extra' || option.type === 'accessory' || option.type === 'component') {
                query.opt.push(option.id.toString())
            } else if (option.type === 'color') {
                query.color = option.id.toString()
            } else if (option.type === 'own-risk') {
                query.own_risk = option.id.toString()
            }
        });

        if (config.hasTradeInVehicle) {
            query.trade_in = 'true'
            if (config.tradeInCarLicense) query.ti_license = config.tradeInCarLicense
            if (config.tradeInCarMileage) query.ti_mileage = config.tradeInCarMileage
        }

        return query
    }
}
