import Model from './Concerns/Model';
import OptionPriceMatrix from '@/app/models/OptionPriceMatrix'

export default class Option extends Model {
    public id!: number;
    public categoryId!: number;
    public categoryName!: string;
    public type!: string;
    public name!: string;
    public information!: string;
    public extraInformation?: string;
    public color!: string;
    public mandatory!: boolean
    public selected!: boolean
    public code!: string;
    public cluster!: string;
    public editionImageId!: number;
    public priceMatrix!: OptionPriceMatrix[];
    public default!: number;

    public get casts(): any {
        return {
            priceMatrix: (models: object[]) => models.map(data => new OptionPriceMatrix(data)),
        };
    }

    protected getUrl(): string {
        return "options";
    }

    public getMatrixPrice(duration: number|null, mileage: number|null): number {
        if (duration == null && mileage == null) {
            return this.priceMatrix[0].price;
        }

        const matrixLine = this.priceMatrix.find(matrix => matrix.duration === duration && matrix.mileage === mileage);

        return matrixLine?.price || 0;
    }

}