import Filters from "@/app/models/Filters";

interface FiltersState {
    mileage: number | null,
    duration: number | null,
    mileages: { value: number }[],
    durations: { value: number }[],
    brands: { id: number, name: string }[],
    fuelTypes: { id: number, name: string }[],
    transmissions: { value: string, name: string }[],
    bodyWorks: { name: string }[],
}

const state: FiltersState = {
    mileage: null,
    duration: null,
    mileages: [],
    durations: [],
    brands: [],
    fuelTypes: [],
    transmissions: [],
    bodyWorks: [],
};

const filtersModule = {
    namespaced: true,
    state: state,
    mutations: {
        setMileage(state: FiltersState, mileage: number) {
            state.mileage = mileage;
        },
        setDuration(state: FiltersState, duration: number) {
            state.duration = duration;
        },
        setFilters(state: FiltersState, filters: Filters) {
            state.mileages = filters.mileages;
            state.durations = filters.durations;
            state.brands = filters.brands;
            state.fuelTypes = filters.fuelTypes;
            state.transmissions = filters.transmissions;
            state.bodyWorks = filters.bodyWorks;
            if (state.mileage === null && state.mileages.length > 0) {
                state.mileage = state.mileages[0].value;
            }
            if (state.duration === null && state.durations.length > 0) {
                state.duration = state.durations[0].value;
            }
        }
    },
}

export default filtersModule;