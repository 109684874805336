import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '@/views/Home.vue';
import Model from '@/views/Model.vue';
import Compare from '@/views/Compare.vue';
import Edition from '@/views/Edition.vue';
import SaveConfiguration from '@/views/SaveConfiguration.vue';
import SaveConfigurationConfirmation from '@/views/SaveConfigurationConfirmation.vue';
import Question from '@/views/Question.vue';
import QuestionConfirmation from '@/views/QuestionConfirmation.vue';
import Order from '@/views/Order.vue';
import OrderConfirmation from '@/views/OrderConfirmation.vue';
import Call from '@/views/Call.vue';
import CallConfirmation from '@/views/CallConfirmation.vue';

Vue.use(VueRouter);

export default [
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/save',
        name: 'save',
        // @ts-ignore
        component: SaveConfiguration,
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/car/:carName/:id/editions',
        name: 'car.editions',
        component: Model
    },
    {
        path: '/vergelijken',
        name: 'compare',
        // @ts-ignore
        component: Compare
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/save-confirmation',
        name: 'save-confirmation',
        // @ts-ignore
        component: SaveConfigurationConfirmation,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/question',
        name: 'question',
        // @ts-ignore
        component: Question,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/question-confirmation',
        name: 'question-confirmation',
        // @ts-ignore
        component: QuestionConfirmation,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/order',
        name: 'order',
        // @ts-ignore
        component: Order,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/order-confirmation',
        name: 'order-confirmation',
        // @ts-ignore
        component: OrderConfirmation,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/call',
        name: 'call',
        // @ts-ignore
        component: Call,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId/call-confirmation',
        name: 'call-confirmation',
        // @ts-ignore
        component: CallConfirmation,
    },
    {
        path: '/car/:carName/:carId/editions/:editionName/:editionId',
        name: 'car.editions.show',
        // @ts-ignore
        component: Edition
    },
] as Array<RouteConfig>;
