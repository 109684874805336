var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view view-model"},[_c('ProgressBar',{attrs:{"step":1,"back-url-parameters":{
      name: 'Home',
      query: {
        mileage: this.mileage ? this.mileage : undefined,
        duration: this.duration ? this.duration : undefined
      }
    }}}),_c('div',{staticClass:"main"},_vm._l((_vm.car.editions),function(edition){return _c('edition-card',{key:edition.id,attrs:{"edition":edition,"mileage":_vm.mileage,"duration":_vm.duration,"close-information":_vm.closeInformation,"close-options":_vm.closeOptions},on:{"toggle-information":_vm.onToggleInformation,"toggle-options":_vm.onToggleOptions}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }