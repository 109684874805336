
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator"
import Client from "@/app/http/Client"
import Location from "@/app/models/Location"

@Component
export default class SelectLocation extends Vue {
  locations: Array<Location> | null = null
  selected: Location | null = null

  @Model('change', { required: false, default: null }) location!: Location | null

  @Prop({ required: false, default: "" }) name!: string

  @Prop({ required: false, default: "" }) id!: string

  @Prop({ required: false, default: false }) required!: Boolean

  @Emit("change")
  onChangeValue() {
    return this.selected
  }

  @Emit("hide")
  hideElement(): void {}

  async getLocations(): Promise<any> {
    return await Client.get("/locations")
  }

  setLocations(locations: Array<Location> | null): void {
    this.locations = locations

    if (null === locations || locations.length == 0) {
      this.hideElement()
    }
  }

  async beforeMount() {
    await this.getLocations()
      .then((response: any) => {
        this.setLocations(response.data)
      })
      .catch((error) => {
        this.setLocations(null)
        console.debug(error)
      })
  }
}
