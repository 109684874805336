import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export type OnForbiddenCallback = (response: AxiosResponse) => Promise<void>
export type OnUnauthorizedCallback = (response: AxiosResponse) => Promise<void>
export type OnUnprocessableEntityCallback = (error: AxiosError) => Promise<void>
export type OnInternalServerErrorCallback = (error: AxiosError) => Promise<void>

function createAxiosInstance(): AxiosInstance {
    const instance = axios.create({withCredentials: true});

    instance.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            if (!error.response) {
                return Promise.reject(error);
            }

            switch (error.response.status) {
                case 401:
                    Client.onUnauthorized(error.response);
                    break;
                case 403:
                    Client.onForbidden(error.response);
                    break;
                case 422:
                    Client.onUnprocessableEntity(error);
                    break;
                case 500:
                    Client.onInternalServerError(error);
                    break;
            }

            return Promise.reject(error);
        }
    );

    return instance;
}

export default class Client {
    public static onForbidden: OnForbiddenCallback;
    public static onUnauthorized: OnUnauthorizedCallback;
    public static onUnprocessableEntity: OnUnprocessableEntityCallback;
    public static onInternalServerError: OnInternalServerErrorCallback;
    private static axios: AxiosInstance = createAxiosInstance();

    public static get baseUrl(): string {
        return Client.axios.defaults.baseURL!;
    }

    public static set baseUrl(baseUrl: string) {
        Client.axios.defaults.baseURL = baseUrl;
    }

    public static hasHeader(header: string) {
        return Client.axios.defaults.headers.hasOwnProperty(header);
    }

    public static setHeader(header: string, value: string): void {
        Client.axios.defaults.headers[header] = value;
    }

    public static get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('get', url, config);
    }

    public static head<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('head', url, config);
    }

    public static options<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('options', url, config);
    }

    public static post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('post', url, data, config);
    }

    public static patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('patch', url, data, config);
    }

    public static put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('put', url, data, config);
    }

    public static delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('delete', url, config);
    }

    private static request<T>(method: string, ...args: any[]): Promise<AxiosResponse<T>> {
        return new Promise<AxiosResponse<T>>((resolve, reject) => {
            // @ts-ignore
            this.axios[method]<T>(...args)
                .then(resolve)
                .catch(reject);
        });
    }
}