import {AxiosResponse} from "axios";
import Client from "@/app/http/Client";

interface FilterResponse {
    mileages: [],
    durations: [],
    brands: [],
    fuelTypes: [],
    transmissions: [],
    bodyWorks: [],
}

export default class Filters {
    public mileages: [];
    public durations: [];
    public brands: [];
    public fuelTypes: [];
    public transmissions: [];
    public bodyWorks: [];

    constructor(filters: FilterResponse) {
        this.mileages = filters.mileages;
        this.durations = filters.durations;
        this.brands = filters.brands;
        this.fuelTypes = filters.fuelTypes;
        this.transmissions = filters.transmissions;
        this.bodyWorks = filters.bodyWorks;
    }

    public static async get(): Promise<Filters> {
        const response: AxiosResponse<{data: FilterResponse}> = await Client.get('/filters');

        return new Filters(response.data.data);
    }
}