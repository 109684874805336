
import { Component, Prop, Vue } from "vue-property-decorator"
import Option from "@/app/models/Option"
import InfoButton from "@/components/InfoButton.vue"

let uniqueSlug = require("unique-slug")

@Component({
  components: { InfoButton },
})
export default class OptionTooltipToggle extends Vue {
  @Prop({ required: true }) public option!: Option

  get getIdentifier(): string {
    const elements = [this.option.id, this.option.name]
    return uniqueSlug(elements.join("-"))
  }
}
