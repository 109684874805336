import ModuleModel, {OpeningTime} from '@/app/models/Module';
import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Dictionary} from "@/app/helpers/Dictionary";

@Module({namespaced: true})
export default class SettingsModule extends VuexModule {
    public phoneNumber!: string;
    public primaryColor!: string;
    public secondaryColor!: string;
    public defaultPricePerKm!: string;
    public openingTimes!: Dictionary<OpeningTime> | {
        monday: OpeningTime;
        tuesday: OpeningTime;
        wednesday: OpeningTime;
        thursday: OpeningTime;
        friday: OpeningTime;
        saturday: OpeningTime;
        sunday: OpeningTime;
    };
    public style: string = '';

    public leaseForm!: number;

    @Mutation
    set(settings: ModuleModel) {
        this.phoneNumber = settings.phoneNumber;
        this.primaryColor = settings.primaryColor;
        this.secondaryColor = settings.secondaryColor;
        this.defaultPricePerKm = settings.defaultPricePerKm;
        this.openingTimes = settings.openingTimes;
        this.style = settings.style;
        this.leaseForm = settings.leaseForm;
    }
}
