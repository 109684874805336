<template>

  <div class="view view-confirmation">
    <ProgressBar :step="3" />

    <div class="main">
      <h1>Bedankt voor uw contactaanvraag!</h1>
      <div class="big-tick"></div>
      <p>
        Bedankt voor het verzenden van jouw terugbelverzoek. Wij nemen zo spoedig mogelijk contact met je op!
      </p>

      <CallToActionButton
        class="btn-primary icon--left"
        icon="chevron-left"
        :to="{
          name: 'car.editions.show',
          params: {
            carName: kebabCase($route.params.carName),
            carId: $route.params.carId,
            editionName: kebabCase($route.params.editionName),
            editionId: $route.params.editionId,
          },
        }"
      >
        Terug naar samenstelling
      </CallToActionButton>
    </div>
  </div>

</template>

<script>
import ProgressBar from "@/components/ProgressBar"
import CallToActionButton from "@/components/CallToActionButton"

export default {
  name: "CallConfirmation",
  components: {
    ProgressBar,
    CallToActionButton
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/globals";
</style>
