import '@/assets/scss/globals.scss';
import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faCheck,
    faTimes,
    faCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faFilter,
    faPlus,
    faPhone,
    faCar,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import Client from "@/app/http/Client";
import Module from "@/app/models/Module";
import helpers from "@/app/mixins/helpers";
import VueMeta from 'vue-meta';
import VueRouter from "vue-router";
import VeeValidate from "vee-validate";
import routes from '@/routes';
import FakeStickyDirective from "@/app/helpers/FakeStickyDirective";
import { useGetOffsetValue } from "@/app/helpers/StickyOffset";

Vue.component('font-awesome-icon', FontAwesomeIcon);

config.autoAddCss = false;
library.add(faPlus);
library.add(faCircle);
library.add(faCheck);
library.add(faTimes);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faFilter);
library.add(faPhone);
library.add(faCar);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);

Vue.config.productionTip = false;

Vue.filter('currencyFormat',
    (value?: number) => value != null ? value.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    }).replace(',00', ',-') : value
);
Vue.filter('mileageFormat',
    (value?: number) => value != null ? value.toLocaleString('nl-NL', {}) : value
);

Vue.use(VeeValidate, {
    classes: true
});

Vue.use(VueMeta);
Vue.mixin(helpers);

Vue.use(FakeStickyDirective)

Client.baseUrl = process.env.VUE_APP_API_URL;

class CarLease extends HTMLElement {
    async connectedCallback() {
        // Insert fonts.
        this.provideFonts();

        // Create shadow dom
        const shadow = this.attachShadow({mode: "open"});

        // Create mount point
        const hook = document.createElement("div");
        hook.id = "app";
        shadow.appendChild(hook);

        // Get settings
        let prefix: any = null;

        let baseUrl = '';

        if (process.env.VUE_APP_BASE_URL) {
            baseUrl = process.env.VUE_APP_BASE_URL;
        }

        if (process.env.VUE_APP_DEALER_TOKEN) {
            Client.setHeader("authorization", "Bearer " + process.env.VUE_APP_DEALER_TOKEN);
        }

        if (process.env.VUE_APP_PREFIX) {
            prefix = process.env.VUE_APP_PREFIX;
        }

        if (this.getAttribute("dealer")) {
            Client.setHeader('authorization', 'Bearer ' + this.getAttribute("dealer"));
        }

        if (this!.getAttribute("prefix")) {
            prefix = this.getAttribute("prefix") ?? ""
        }

        // First load FontAwesome CSS
        const faStyle = document.createElement("style");
        faStyle.type = 'text/css';
        faStyle.innerText = dom.css();
        shadow.appendChild(faStyle);

        // First load CSS
        const cssLink = document.createElement("link");
        cssLink.href = baseUrl + "/css/app.css";
        cssLink.rel = "stylesheet";
        cssLink.onload = () => {
            const offsetElement = this

            // Create and mount app
            let router = new VueRouter({
                routes,
                base: prefix,
                scrollBehavior (to, from, savedPosition) {
                    if (savedPosition) {
                        return savedPosition
                    }
                    return { x: 0, y: useGetOffsetValue(offsetElement, 'top') + offsetElement.offsetTop }
                }
            });

            new Vue({
                router,
                store,
                async beforeMount() {
                    this.$store.commit('settings/set', await Module.getForDealer());
                },
                render: h => h(App),
            }).$mount(hook);
        };
        shadow.appendChild(cssLink);

    }

    public provideFonts() {
        // Provide app fonts in the parent page in order to avoid issues
        // with shadow dom and @font-face.

        const fonts: Array<string> = [
            "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap",
        ];

        // Google fonts optimization.
        if (fonts.filter((string) => string.includes("//fonts.googleapis.com/"))) {

            // Preconnect.
            const preConnectNode = document.createElement("link");
            preConnectNode.setAttribute("rel", "preconnect");
            preConnectNode.setAttribute("href", "https://fonts.googleapis.com");
            document.head.appendChild(preConnectNode);

            // Crossorigin.
            const crossOriginNode = document.createElement("link");
            crossOriginNode.setAttribute("rel", "preconnect");
            crossOriginNode.setAttribute("href", "https://fonts.gstatic.com");
            crossOriginNode.setAttribute("crossorigin", "");
            document.head.appendChild(crossOriginNode);
        }

        for (let i = 0; i < fonts.length; i++) {
            const fontLinkNode = document.createElement("link");
            fontLinkNode.setAttribute("rel", "stylesheet");
            fontLinkNode.setAttribute(
                "href",
                fonts[i]
            );
            document.head.appendChild(fontLinkNode);
        }
    }

}

customElements.define("car-lease", CarLease);
