
import Vue from "vue"
import Component from "vue-class-component"
import {Emit, Model, Prop} from "vue-property-decorator"
import CollapsibleThing from "@/components/CollapsibleThing.vue"
import Dropdown from "@/components/Dropdown.vue"

@Component({
  // @ts-ignore
  components: { Dropdown, CollapsibleThing }
})
export default class FilterItem extends Vue {
  @Model() appliedFilters!: any
  @Prop() label!: string
  @Prop({default: () => ({})}) aggregations!: { [key: string]: any }
  @Prop() aggField!: string
  @Prop() aggSubField!: string
  @Prop() options!: object[]
  @Prop() field!: string
  @Prop({default: "id"}) value!: string
  @Prop() subField!: string
  @Prop({default: null}) subOptions!: string | null
  @Prop({default: "id"}) subValue!: string
  @Prop({default: false}) closed!: boolean

  get indicator(): number {
    const fieldCount = this.appliedFilters[this.field]?.length || 0
    const subFieldCount = this.appliedFilters[this.subField]?.length || 0

    return fieldCount + subFieldCount
  }

  removeValue(filter: Array<any>, value: any): void {
    let index = filter.indexOf(value);
    while (index >= 0) {
      filter.splice(index, 1);
      index = filter.indexOf(value);
    }
  }

  checkOption(option: any): void {
    let filter = this.appliedFilters[this.field] || [];
    const value = option[this.value];
    let selected = option.selected;
    if (selected && option[this.subOptions!]) {
      option[this.subOptions!].forEach((subOption: any) => {
        selected = selected && !subOption.selected;
      })
    }

    if (selected) {
      filter.push(value);
    } else {
      this.removeValue(filter, value);
    }
    this.appliedFilters[this.field] = filter;
  }

  checkSubOption(option: any, subOption: any): void {
    let filter = this.appliedFilters[this.subField] || [];
    const value = subOption[this.subValue];
    if (subOption.selected) {
      filter.push(value)
    } else {
      this.removeValue(filter, value);
    }
    if (option) {
      this.checkOption(option);
    }
    this.appliedFilters[this.subField] = filter;
  }

  @Emit("change")
  onOptionChange(option: any) {
    if (option[this.subOptions!]) {
      option[this.subOptions!].forEach((subOption: any) => {
        subOption.selected = false;
        this.checkSubOption(null, subOption);
      })
    }
    this.checkOption(option);

    return this.appliedFilters
  }

  @Emit("change")
  onSubOptionChange(option: any, subOption: any) {
    this.checkSubOption(option, subOption);

    return this.appliedFilters
  }
}
