
export type Waitable = () => boolean;

export default async function waitFor(callback: Waitable, maxWaitIterations: number = 10): Promise<void> {
    let i = 0;
    do {
        if (!callback()) {
            i++;
            continue;
        }
        return;
    } while (i < maxWaitIterations)
}