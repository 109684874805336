import Model from './Concerns/Model';
import Client from "@/app/http/Client";
import {Dictionary} from "@/app/helpers/Dictionary";

export type OpeningTime = {
    start_time: string
    end_time: string
}

export default class Module extends Model {
    public style!: string;
    public phoneNumber!: string;
    public primaryColor!: string;
    public secondaryColor!: string;
    public defaultPricePerKm!: string;
    public leaseForm!: number;

    public openingTimes!: Dictionary<OpeningTime> | {
        monday: OpeningTime;
        tuesday: OpeningTime;
        wednesday: OpeningTime;
        thursday: OpeningTime;
        friday: OpeningTime;
        saturday: OpeningTime;
        sunday: OpeningTime;
    };

    static async getForDealer(): Promise<Module> {
        const response = await Client.get('modules/settings');

        //@ts-ignore
        return new Module(response.data.data);
    }

    protected getUrl(): string {
        return "modules";
    }
}
