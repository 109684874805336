<template>

  <div class="view view-confirmation">
    <ProgressBar :step="3" />

    <div class="main">
      <h1>Samenstelling verstuurd!</h1>
      <div class="big-tick"></div>

      <CallToActionButton
        class="btn-primary icon--left"
        icon="chevron-left"
        :to="{
          name: 'car.editions.show',
          params: {
            carName: kebabCase($route.params.carName),
            carId: $route.params.carId,
            editionName: kebabCase($route.params.editionName),
            editionId: $route.params.editionId,
          },
        }"
      >
        Terug naar samenstelling
      </CallToActionButton>
    </div>
  </div>

</template>

<script>
import ProgressBar from "@/components/ProgressBar"
import CallToActionButton from "@/components/CallToActionButton"

export default {
  name: "SaveConfigurationConfirmation",
  components: {
    ProgressBar,
    CallToActionButton,
  },
  methods: {
    backUrlParameters() {
      return {
        name: "car.editions.show",
        params: {
          carName: kebabCase(this.edition.car.name),
          id: this.edition.car.id,
          editionName: kebabCase(this.edition.name),
          editionId: this.edition.id,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/globals";
</style>
