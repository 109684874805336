// @ts-nocheck
const FakeStickyDirective = {
    _init: (el, binding) => {
        el.style = null

        const computedStyle = window.getComputedStyle(el)
        const styleFilters = ['auto', 'unset', 'initial', 'revert']

        el.__FakeStickyEnabled__ = binding.value?.sticky == undefined ? true : !!binding.value?.sticky
        el.__FakeStickyOffset__ = {
            'top': (styleFilters.indexOf(computedStyle?.top) === -1 ? computedStyle.top : ''),
            'bottom': (styleFilters.indexOf(computedStyle?.bottom) === -1 ? computedStyle.bottom : ''),
            'preset': getCssNumberValue(String(binding.value?.offset ?? 0)),
        }
    },
    _bind: (el, binding) => {
        FakeStickyDirective._init(el, binding)
        if (!el.__FakeStickyEnabled__) return

        const scrollParent = getScrollParent(el)
        el.__FakeStickyScrollParent__ = (scrollParent !== document.body ? scrollParent : window)

        el.__FakeStickyObserver__ = new IntersectionObserver((entries, observer) => {
            el.__FakeStickyScrollParent__.removeEventListener('scroll', el.__FakeStickyScrollHandler__)
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    el.__FakeStickyScrollParent__.addEventListener('scroll', el.__FakeStickyScrollHandler__ = () => {
                        FakeStickyDirective._handleScroll(el)
                    })
                }
            })
        }, {
            root: (scrollParent !== document.body ? scrollParent : document)
        })
        el.__FakeStickyObserver__.observe(el.parentElement)

        FakeStickyDirective._handleScroll(el)
    },
    _unbind: (el, binding) => {
        if (!el.__FakeStickyEnabled__) return
        el.__FakeStickyScrollParent__.removeEventListener('scroll', el.__FakeStickyScrollHandler__)

        if (!el?.__FakeStickyObserver__ || !el?.parentElement) return
        el.__FakeStickyObserver__.unobserve(el.parentElement)
    },
    _handleScroll: (el) => {
        const style = window.getComputedStyle(el)

        if (style?.position !== 'sticky') return
        if (!el.offsetParent) return

        const scrollTop = el.__FakeStickyScrollParent__.scrollTop ?? (window.scrollY - getOffsetTop(el.offsetParent))
        const scrollMax = (el.offsetParent.offsetHeight - el.offsetHeight)
        const offsetPos = Math.min(scrollMax, Math.max(0, scrollTop))

        if (el.__FakeStickyOffset__.top) {
            el.style.top = `calc(
                ${ el.__FakeStickyOffset__.top } + 
                ${ el.__FakeStickyOffset__.preset } + 
                ${ offsetPos }px
            )`
        }

        if (el.__FakeStickyOffset__.bottom) {
            el.style.bottom = `calc(
                100% - 
                100vh - 
                ${ el.__FakeStickyOffset__.bottom } + 
                ${ el.__FakeStickyOffset__.preset } -
                ${ offsetPos }px
            )`
        }

        el.offsetParent.style.overflow = 'hidden'
    },
    inserted: (el, binding, vnode, oldVnode) => {
        FakeStickyDirective._bind(el, binding)
    },
    componentUpdated: (el, binding, vnode, oldVnode) => {
        FakeStickyDirective._handleScroll(el)
        if (binding.value === binding.oldValue) return
        FakeStickyDirective._unbind(el, binding)
        FakeStickyDirective._bind(el, binding)
    },
    unbind: (el, binding, vnode, oldVnode, isDestroy) => {
        FakeStickyDirective._unbind(el, binding)
    }
}

export default (app) => {
    app.directive('fake-sticky', FakeStickyDirective)
}

/**
 * Get Scroll Parent for element.
 *
 * Returns the nearest scroll parent for and element, or document.body.
 *
 * @param {HTMLElement} node
 *   The element to find the scroll parent for.
 *
 * @returns {null|Element|*|HTMLElement}
 *   Returns the nearest scroll parent HTML element (or document.body).
 *
 * @note see: https://gist.github.com/oscarmarina/3a546cff4d106a49a5be417e238d9558
 */
const getScrollParent = (node) => {
    let el = node
    if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
        return null
    }
    if (el instanceof ShadowRoot) {
        el = el.host
    }
    const style = window.getComputedStyle(el)
    const overflow = style.overflowY
    const isScrolled = el.scrollHeight > el.clientHeight
    if (isScrolled && !overflow.includes('visible') && !overflow.includes('hidden')) {
        return el
    }
    return getScrollParent(el.parentNode) || document.body
}

/**
 * Get offset to the top of the document.
 *
 * Returns the total offset from the element
 * to the top of the document.
 * Traverses up the document tree to find the
 * offset of the node to the top of the document.
 *
 * @param {HTMLElement} node
 *   The element to get the offset for.
 *
 * @returns {int}
 *   Returns the total offset to the top of the document.
 */
const getOffsetTop = (node) => {
    let el = node
    if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
        return null
    }
    return getOffsetTop(node.offsetParent) + node.offsetTop
}

/**
 * Get CSS number value.
 *
 * Covert input value to CSS ready value in (px, em, rem or %).
 *
 * @param {string} input
 *   The input value, as string.
 *
 * @returns {string}
 *   Returns a ready to use string value with px, em, rem or % suffix.
 */
const getCssNumberValue = (input = '') => {
    const number = parseFloat(input)
    const relative = String(input).match(/%|rem|em/)
    return (isNaN(number)) ? '0px' : (relative) ? number + relative : Math.round(number) + 'px'
}
