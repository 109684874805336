export enum LeaseType {
    PRIVATE_LEASE = 1,
    OPERATIONAL_LEASE = 2,
}

export function resolveLeaseType(value: number): LeaseType {
  return value as LeaseType
}

export function getVatLabel(leaseType: LeaseType): string {
    switch (leaseType) {
        case LeaseType.PRIVATE_LEASE:
            return 'incl. BTW'
        case LeaseType.OPERATIONAL_LEASE:
            return 'excl. BTW'
  }
}

export function isPrivateLease(value: number): Boolean {
    let leaseTypeEnum = resolveLeaseType(value)
    return leaseTypeEnum === LeaseType.PRIVATE_LEASE
}

export function isOperationalLease(value: number): Boolean {
    let leaseTypeEnum = resolveLeaseType(value)
    return leaseTypeEnum === LeaseType.OPERATIONAL_LEASE
}
