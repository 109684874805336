import {AxiosResponse} from 'axios';
import Client from '@/app/http/Client';

export default abstract class Model {
    public id!: number|string;

    constructor(data: any) {
        this.setProperties(data);
    }

    protected abstract getUrl(): string;

    public static get url() {
        const constructor = this as Function;

        // @ts-ignore
        return (new constructor({})).getUrl();
    }

    public get casts(): any {
        return {};
    }

    public static async get(params: object = {}, withMeta: boolean = false) {
        const response: AxiosResponse<any> = await Client.get(this.url, {
            params,
        });

        //@ts-ignore
        let data = response.data.data.map((item: any) => new this(item));
        return withMeta ? {data, meta: response.data.meta} : data;
    }

    public static async show(id: number|string, params: object = {}) {
        const response: AxiosResponse<any> = await Client.get(this.url + '/' + id, {
            params,
        });

        //@ts-ignore
        return new this(response.data);
    }

    private setProperties(data: any) {
        let parsed = Object.entries(data).map((entry: any[]) => {
            return [entry[0], (this.casts[entry[0]] || ((e: any) => e))(entry[1])];
        });

        Object.assign(this, Object.fromEntries(parsed));
    }
}
