<script>
import ProgressBar from "@/components/ProgressBar"
import Client from "@/app/http/Client"
import SelectLocation from "@/components/SelectLocation"
import kebabCase from "lodash/kebabCase"
import QueryParams from "@/app/helpers/QueryParams";
import PriceConditionCard from "@/components/sidebar/PriceConditionCard.vue";
import SideBar from "@/components/sidebar/SideBar.vue";
import SummaryCard from "@/components/sidebar/SummaryCard.vue";
import RadioOrCheckBox from "@/components/RadioOrCheckBox.vue";
import { getRelationTrackSiteId } from "@/app/helpers/RelationTrackSiteId"
import { isOperationalLease } from "@/app/enums/LeaseType"

export default {
  name: "Call",
  components: {
    SummaryCard,
    SideBar,
    PriceConditionCard,
    SelectLocation,
    ProgressBar,
    RadioOrCheckBox,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL + "/storage/",
      config: null,
      salutation: null,
      firstName: null,
      lastName: null,
      phone: null,
      location: null,
      relationTrackSiteId: null,
      checkboxes: [
        {
          name: "acceptedTermsAndConditions",
          text: "Ik heb de privacyverklaring gelezen",
          checked: false,
          required: true,
        },
        {
          name: "wantsNewsletter",
          text: "Houd mij op de hoogte van nieuws en aanbiedingen",
          checked: false,
          required: false,
        },
      ],
      showLocationSelector: true,
    }
  },
  async beforeMount() {
    const configuration = await QueryParams.parse(this.$route.query, this.$route.params.editionId)
    this.config = configuration
  },
  created() {
    this.relationTrackSiteId = getRelationTrackSiteId()
  },
  methods: {
    isValid() {
      if (!this.isFormDirty()) {
        return false
      }
      return !Object.keys(this.fields).some(
          (key) => this.fields[key].valid === false
      ) && !this.checkboxes.some(
          (checkbox)=> checkbox.required && !checkbox.checked
      )
    },
    isFormDirty() {
      return Object.keys(this.fields).some(
          (key) => this.fields[key].dirty || this.fields[key].changed
      )
    },
    hideSelectLocations() {
      this.showLocationSelector = false
    },
    async storeCallRequest() {
      await Client.post("call-back-requests", {
        edition: this.config.edition.id,
        duration: this.config.duration,
        mileage: this.config.mileage,
        totalPrice: this.config.totalPrice(),
        hasTradeInVehicle: this.config.hasTradeInVehicle,
        exchangeLicence: this.config.tradeInCarLicense,
        exchangeMileage: this.config.tradeInCarMileage,
        options: this.config.selectedOptions.map((option)=> {
          return {
            "id": option.id,
            "code": option.code,
            "type": option.type,
            "name": option.name,
            "price": option.getMatrixPrice(this.config.duration, this.config.mileage)
          }
        }),
        salutation: this.salutation,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phone,
        question: this.question,
        locationId: this.location?.id,
        acceptedTermsAndConditions: this.checkboxes[0].checked,
        wantsNewsletter: this.checkboxes[1].checked,
      })

      await this.$router.push("call-confirmation")
    },
    backUrlParameters() {
      let query = QueryParams.build(this.config)
      return {
        name: "car.editions.show",
        params: {
          carName: kebabCase(this.config.edition.carName),
          id: this.config.edition.carId,
          editionName: kebabCase(this.config.edition.name),
          editionId: this.config.edition.id,
        },
        query,
      }
    },
  },
}
</script>

<template>

  <div v-if="config" class="view view-contact-form">
    <ProgressBar :disabled="true" :step="2" :back-url-parameters="backUrlParameters()" />

    <div class="main">
        <h1>Bel mij terug</h1>

        <p>
          Wil je meer informatie of heb je een vraag over deze auto en wil je graag dat wij terugbellen?
          Laat jouw gegevens achter en wij bellen zo spoedig mogelijk terug.
        </p>

        <form class="form">

          <div class="form-item salutation">
            <label>Aanhef</label>
            <div class="form-radios">
              <RadioOrCheckBox
                v-for="item in [{ key: 'dhr', value: 'Dhr.' }, { key: 'mevr', value: 'Mevr.' }]"
                :key="item.key"
                v-model="salutation"
                :is-box="false"
                :text-highlighted="false"
                checked-color="#0274B2"
                color="white"
                name="salutation"
                radius="16"
                type="radio"
                :value="item.key"
                v-validate
                required
              >
                {{ item.value }}
              </RadioOrCheckBox>
            </div>
          </div>

          <div class="form-item firstname">
            <label for="firstname">Voornaam</label>
            <input
              id="firstname"
              v-model="firstName"
              v-validate="'min:2'"
              required
              name="firstname"
              type="text"
            />
          </div>

          <div class="form-item lastname">
            <label for="lastname">Achternaam</label>
            <input
              id="lastname"
              v-model="lastName"
              v-validate="'min:2'"
              required
              name="lastname"
              type="text"
            />
          </div>

          <div class="form-item phone">
            <label for="phone">Telefoonnummer</label>
            <input
              id="phone"
              v-model="phone"
              v-validate="'min:10|regex:[\+\-0-9]'"
              required
              name="phone"
              type="tel"
            />
          </div>

          <div v-if="showLocationSelector" class="form-item location">
            <label for="locations">Kies een locatie</label>
            <div class="form-select">
              <SelectLocation
                :id="'locations'"
                v-model="location"
                v-validate="'required'"
                :name="'locations'"
                :force="true"
                :required="true"
                @hide="hideSelectLocations($event)"
                class="plain"
              >
                <template #placeholder>Selecteer een locatie</template>
              </SelectLocation>
            </div>
          </div>

          <div class="form-item checkboxes">
            <div class="form-checkboxes">
              <RadioOrCheckBox
                v-for="checkbox in checkboxes"
                :key="checkbox.name"

                v-model="checkbox.checked"
                :border-color="checkbox.checked ? '#0274b2' : '#979797'"
                :is-box="true"
                :text-highlighted="false"
                checked-color="#0274B2"
                color="white"
                :name="checkbox.name"
                radius="16"
                type="checkbox"
                v-validate
                :required="checkbox.required"
              >
                {{ checkbox.text }}
              </RadioOrCheckBox>
            </div>
          </div>

          <div class="form-item form-actions">
            <div>
              <button :disabled="!isValid()" @click.prevent="storeCallRequest">
                Bel mij terug
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
          </div>
        </form>
    </div>

    <SideBar class="right">
      <SummaryCard title="Jouw overzicht" :config="config" :showThumb="true"></SummaryCard>
      <PriceConditionCard :config="config"></PriceConditionCard>
    </SideBar>
  </div>

</template>

<style lang="scss" scoped>
@import "src/assets/scss/form-contact";
</style>
