
import { Component, Vue } from "vue-property-decorator"

@Component({
  metaInfo() {
    return {
      style: [{ cssText: this.$store.state.settings.style }],
    }
  },
})
export default class App extends Vue {}
