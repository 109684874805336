
import {Component, Prop, Vue} from "vue-property-decorator"
import Edition from "@/app/models/Edition"
import RationalImage from "@/components/RationalImage.vue"
import Car from "@/app/models/Car"
import CollapsibleThing from "@/components/CollapsibleThing.vue"
import kebabCase from "lodash/kebabCase"
import PriceIndication from "@/app/models/PriceIndication"
import {getVatLabel, resolveLeaseType} from "@/app/enums/LeaseType"
import Option from "@/app/models/Option";
import InfoButton from "@/components/InfoButton.vue"
import Dropdown from "@/components/Dropdown.vue"

@Component({
  // @ts-ignore
  components: { Dropdown, InfoButton, CollapsibleThing, RationalImage},
})
export default class EditionCard extends Vue {
  @Prop() edition!: Edition
  @Prop() mileage!: number
  @Prop() duration!: number
  @Prop() closeInformation!: boolean
  @Prop() closeOptions!: boolean

  fromPrice?: PriceIndication

  $parent!: { car: Car } & Vue

  get car(): Car {
    return this.$parent.car
  }

  kebabCase(str: string): string {
    return kebabCase(str)
  }

  async beforeMount() {
    this.fromPrice = this.edition.getFromPrice(this.mileage, this.duration);
  }

  get vatLabel(): string {
    const vatLabel = this.$store.state.settings.leaseForm
    return getVatLabel(resolveLeaseType(vatLabel))
  }

  get defaultOptions(): Option[] {
    return this.edition.options.filter((option) => {
      return option.type === 'default';
    })
  }

  onToggleInformation(val: any) {
    this.$emit('toggle-information', val)
  }

  onToggleOptions(val: any) {
    this.$emit('toggle-options', val)
  }
}
