import Model from "@/app/models/Model";
import Edition from "@/app/models/Edition";
import Image from "@/app/models/Images";

export default class Car extends Model {
    id!: number;
    name!: string;
    seo!: string;
    editions!: Edition[]

    get fullName(): string {
        return this.name;
    }

    get casts(): any {
        return {
            editions: (editions: any[]) => editions.map((data: any) => new Edition(data)),
        };
    }

    get mainImage(): Image {
        return this.editions[0]!.mainImage;
    }

    protected getUrl(): string {
        return 'cars';
    }
}
