
import { Component, Prop, Vue } from "vue-property-decorator"
import Option from "@/app/models/Option"

let uniqueSlug = require("unique-slug")

@Component({
})
export default class OptionTooltip extends Vue {
  @Prop() public option!: Option

  get getIdentifier(): string {
    const elements = [this.option.id, this.option.name]
    return uniqueSlug(elements.join("-"))
  }
}
