<script>
import ProgressBar from "@/components/ProgressBar"
import Client from "@/app/http/Client"
import kebabCase from "lodash/kebabCase"
import QueryParams from "@/app/helpers/QueryParams";
import PriceConditionCard from "@/components/sidebar/PriceConditionCard.vue";
import SideBar from "@/components/sidebar/SideBar.vue";
import SummaryCard from "@/components/sidebar/SummaryCard.vue";

export default {
  name: "SaveConfiguration",
  components: {
    SummaryCard,
    SideBar,
    PriceConditionCard,
    ProgressBar,
  },
  data() {
    return {
      config: null,
      email: null,
      name: null,
    }
  },
  async beforeMount() {
    const configuration = await QueryParams.parse(this.$route.query, this.$route.params.editionId)
    this.config = configuration
  },
  methods: {
    isValid() {
      if (!this.isFormDirty()) {
        return false
      }
      return !Object.keys(this.fields).some(
          (key) => this.fields[key].valid === false
      )
    },
    isFormDirty() {
      return Object.keys(this.fields).some(
          (key) => this.fields[key].dirty || this.fields[key].changed
      )
    },
    backUrlParameters() {
      let query = QueryParams.build(this.config)
      return {
        name: "car.editions.show",
        params: {
          carName: kebabCase(this.config.edition.carName),
          id: this.config.edition.carId,
          editionName: kebabCase(this.config.edition.name),
          editionId: this.config.edition.id,
        },
        query,
      }
    },
    async requestConfigurationMail() {
      let query = QueryParams.build(this.config)
      const url = window.location.origin + this.$router.resolve({query}).href.replace("/save", "")
      await Client.post("configurations/send", {
        configurationUrl: url,
        name: this.name,
        email: this.email,
      })
      await this.$router.push("save-confirmation")
    }
  },
}
</script>


<template>

  <div v-if="config" class="view view-contact-form">
    <ProgressBar :disabled="true" :step="2" :back-url-parameters="backUrlParameters()" />

    <div class="main">
        <h1>Samenstelling bewaren</h1>

        <p>
          Je wilt er nog even over nadenken en de auto van jouw keuze bewaren om
          het er nog eens over te hebben met je partner, familie of vrienden?
          Begrijpelijk! Bewaar de link naar deze configuratie in je inbox en ga later verder waar je gebleven bent.
        </p>

        <form class="form">

          <div class="form-item name">
            <label for="firstname">Naam</label>
            <input
              id="name"
              v-model="name"
              v-validate="'min:2'"
              required
              name="name"
              type="text"
            />
          </div>

          <div class="form-item email">
            <label for="email">E-mailadres</label>
            <input
              id="email"
              v-model="email"
              v-validate
              required
              name="email"
              type="email"
            />
          </div>

          <div class="form-item form-actions">
            <div>
              <button :disabled="!isValid()" @click.prevent="requestConfigurationMail">
                Verstuur samenstelling
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
          </div>

        </form>
    </div>

    <SideBar class="right">
      <SummaryCard title="Jouw overzicht" :config="config" :showThumb="true"></SummaryCard>
      <PriceConditionCard :config="config"></PriceConditionCard>
    </SideBar>
  </div>

</template>

<style lang="scss" scoped>
@import "src/assets/scss/form-contact";
</style>
