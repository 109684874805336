var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view view-confirmation"},[_c('ProgressBar',{attrs:{"step":3}}),_c('div',{staticClass:"main"},[_c('h1',[_vm._v("Bedankt voor uw aanvraag!")]),_c('div',{staticClass:"big-tick"}),_c('p',[_vm._v(" Je hebt zojuist je aanvraag voor jouw leaseauto aan ons verzonden, hartelijk dank daarvoor! Een mooi moment met vele prettige kilometers in het vooruitzicht. Wij gaan aan de slag met de verwerking van je aanvraag en nemen zo snel mogelijk contact met je op. ")]),_c('div',{staticClass:"btn-wrapper"},[_c('CallToActionButton',{staticClass:"btn-primary icon--left",attrs:{"icon":"chevron-left","to":"/"}},[_vm._v(" Terug naar homepage ")]),_c('CallToActionButton',{staticClass:"btn-primary icon--left",attrs:{"icon":"chevron-left","to":{
          name: 'car.editions',
          params: {
            carName: _vm.kebabCase(_vm.$route.params.carName),
            id: _vm.$route.params.carId,
          },
        }}},[_vm._v(" Terug naar aanbod ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }