
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class ProgressBar extends Vue {
  @Prop() step!: number
  @Prop() backUrlParameters!: object

  get progress(): string {
    return "progress-" + this.step
  }
}
