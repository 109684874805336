import Vue from 'vue';
import Edition from "@/app/models/Edition";
import kebabCase from 'lodash/kebabCase';

export interface Helpers {
    kebabCase(str: string): string

    formattedPrice(price: number): string

    formattedMileage(mileage: number): string

    formattedSeconds(seconds: number): string

    formattedConsumption(consumption: number): string
}

export default Vue.extend({
    methods: {
        kebabCase,
        formattedPrice(price: number): string | null {
            if (price == null) {
                return price;
            }

            return price.toLocaleString(
                'nl-nl',
                {
                    style: 'currency',
                    currency: 'EUR',
                }
            ).replace(/,00+$/, ',-');
        },
        formattedMileage(mileage: number): string {
            return mileage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        },
        formattedSeconds(seconds: number): string {
            return seconds.toString().replace(/\./g,',')
        },
        formattedConsumption(consumption: number | string): string {
            return consumption.toString().replace(/\./g,',')
        }
    },
});