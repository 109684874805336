
import { Component, Prop, Vue } from "vue-property-decorator"

type Ratio = [number, number]

@Component
export default class RationalImage extends Vue {
  @Prop({
    validator: (value: string) => {
      return /^([0-9]+):([0-9]+)$/.test(value)
    },
  })
  ratio!: string

  get ratios(): Ratio {
    return this.ratio.split(":").map((v: string) => parseInt(v)) as Ratio
  }

  setStyle(): void {
    const width = this.$el.clientWidth
    const widthRatio = this.ratios[0]
    const heightRatio = this.ratios[1]

    const height = (width / widthRatio) * heightRatio

    ;(this.$el as HTMLElement).style.height = height + "px"
    ;(this.$el as HTMLElement).style.maxHeight = height + "px"
  }

  mounted() {
    this.setStyle()
    setTimeout(this.setStyle, 200)
    window.addEventListener("resize", this.setStyle)
  }

  updated() {
    this.setStyle()
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setStyle)
  }
}
