var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view view-confirmation"},[_c('ProgressBar',{attrs:{"step":3}}),_c('div',{staticClass:"main"},[_c('h1',[_vm._v("Bedankt voor uw contactaanvraag!")]),_c('div',{staticClass:"big-tick"}),_c('p',[_vm._v(" Bedankt voor het verzenden van jouw vraag over het onderstaande voertuig. Wij komen hier zo spoedig mogelijk op terug! ")]),_c('CallToActionButton',{staticClass:"btn-primary icon--left",attrs:{"icon":"chevron-left","to":{
        name: 'car.editions.show',
        params: {
          carName: _vm.kebabCase(_vm.$route.params.carName),
          carId: _vm.$route.params.carId,
          editionName: _vm.kebabCase(_vm.$route.params.editionName),
          editionId: _vm.$route.params.editionId,
        },
      }}},[_vm._v(" Terug naar samenstelling ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }