import { render, staticRenderFns } from "./StaticEditionOption.vue?vue&type=template&id=495e226f&scoped=true"
import script from "./StaticEditionOption.vue?vue&type=script&setup=true&lang=ts"
export * from "./StaticEditionOption.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StaticEditionOption.vue?vue&type=style&index=0&id=495e226f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "495e226f",
  null
  
)

export default component.exports